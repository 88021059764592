import React from 'react';
import PropTypes from 'prop-types';
import './EventManagemnent.scss';

const EventManagemnent = () => (
  <div className="EventManagemnent" data-testid="EventManagemnent">
    EventManagemnent Component
  </div>
);

EventManagemnent.propTypes = {};

EventManagemnent.defaultProps = {};

export default EventManagemnent;
