import React from 'react';
import PropTypes from 'prop-types';
import './StudymaterialManagemnent.scss';

const StudymaterialManagemnent = () => (
  <div className="StudymaterialManagemnent" data-testid="StudymaterialManagemnent">
    StudymaterialManagemnent Component
  </div>
);

StudymaterialManagemnent.propTypes = {};

StudymaterialManagemnent.defaultProps = {};

export default StudymaterialManagemnent;
