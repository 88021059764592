import React from 'react';
import PropTypes from 'prop-types';
import './NoticeManagement.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Fab,
  Select,
  Accordion,
  MenuItem,
  Card,
  CardMedia,
  CardContent,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  SelectChangeEvent,
  Button,
  useMediaQuery,
  Modal,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel
} from '@mui/material';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import {
  CreateNoticeApi,
  ListNoticeApi,
  UpdateNoticeApi,
  DeleteNoticeApi
} from '../../services/apis';
import { BEBase } from '../../constants/app.constant';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useAuth } from '../../services/authContext';
import { format } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';

const VisuallyHiddenInput = {
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#284d84' // blue color
    }
  }
})

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%", // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '190px',
  '@media (max-width: 600px)': { // Adjust the breakpoint as needed
    width: '100%', // Width for mobile devices
  }
};


const NoticeManagement = () => {
  const { loginStatus, setLoginStatus } = useAuth();
  const [day, setDay] = React.useState('thursday');
  const [value, setValue] = React.useState(2);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [recipient, setRecipient] = React.useState('ALL');
  const [title, setTitle] = React.useState('');
  const [notice, setNotice] = React.useState('');
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [heading, setHeading] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [paymentLink, setPaymentLink] = React.useState('');
  const [listApiFlag, setListApiFlag] = React.useState(true);
  const [files, setFiles] = React.useState(null); // State to store uploaded files
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [selectedNotice, setSelectedNotice] = React.useState(null);
  const [notices, setNotices] = React.useState([]);
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState('');

  const handleFileChange = event => {
    setFiles(event.target.files)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  React.useEffect(() => {
    const loginStat = localStorage.getItem('loginCred')
    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat))
    }
    if (listApiFlag && loginStatus.user && loginStatus.user.is_superuser) {
      const fetchListProducts = async () => {
        try {
          const response = await ListNoticeApi({
            id: JSON.parse(loginStatus.user.id)
          })
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListProducts()
    }
  }, [loginStatus])

  const filteredUsers = listOfUsers.filter(user =>
    user.title.toLowerCase().includes(filter.toLowerCase())
  )

  const handleAccordianAction = async (action, item) => {
    if (action.toLowerCase() === 'delete') {
      setDeleteProductOpen(true)
      setSelectedID(item.id)
    } 
  }

  const handleDeleteProduct = async (deleteId) => {
    try {
      const formData = new FormData();
      formData.append('id', selectedID);
  
      // Assuming DeleteCourseApi expects formData
      const response = await DeleteNoticeApi(formData);
      
      console.log("Product deleted successfully:", response);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error("Error deleting Product:", error);
    }
  };


  const handleUpdateOpen = notice => {
    setSelectedNotice(notice)
    setRecipient(notice.recipient)
    setTitle(notice.title)
    setNotice(notice.notice_text)
    setUpdateOpen(true)
  }

  const handleUpdateClose = () => {
    setUpdateOpen(false)
    setRecipient('')
    setTitle('')
    setNotice('')
    setFiles(null)
  }

  const handleUpdateSubmit = async event => {
    event.preventDefault()
    try {
      const formData = new FormData()
      formData.append('id', selectedNotice.id)
      formData.append('recipient', recipient)
      formData.append('title', title)
      formData.append('notice', notice)
      formData.append('noticeFile', files)

      // Call your update API
      const response = await UpdateNoticeApi(formData) // Replace with actual API call
      console.log('Notice updated successfully:', response)

      // Refresh notices after successful update
      setNotices(prevNotices =>
        prevNotices.map(item =>
          item.id === selectedNotice.id
            ? { ...item, recipient, title, notice }
            : item
        )
      )
      handleUpdateClose()
      window.location.reload()
    } catch (error) {
      console.error('Error updating notice:', error)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      const formData = new FormData()

      // Append text fields
      formData.append('recipient', recipient)
      formData.append('title', title)
      formData.append('notice', notice)
      formData.append('noticeFile', files)
      formData.append('adminToken', loginStatus.token)
      // Make the API request
      console.log('kkk', recipient, title, notice, files, loginStatus.token)
      const response = await CreateNoticeApi(formData)

      console.log('Notice created successfully:', response)
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating notice:', error)
    }
  }

  const handleChange = event => {
    setDay(event.target.value)
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 1,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          marginTop: { xs: '80px', md: '130px' } // Adjust top margin for smaller screens
        }}
      >
        <AppBar
          position='fixed'
          color='primary'
          sx={{ marginTop: isMobile ? '56px' : '130px' }}
        >
          <Toolbar>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='back'
              sx={{ mr: 2 }}
            >
              <ArrowBack
                sx={{
                  backgroundcolor: 'black',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '50%'
                }}
                component={Link}
                to='/'
              />
            </IconButton>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              Notice
            </Typography>
          </Toolbar>
        </AppBar>

        <Container
          sx={{
            flexGrow: 1,
            mt: { xs: 2, md: 5 }, // Margin for small and medium screens
            mb: { xs: 5, md: 7 }, // Adjust bottom margin
            bgcolor: 'background.paper'
          }}
        >
          <Modal
            open={updateOpen}
            onClose={handleUpdateClose}
            aria-labelledby='update-modal-title'
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? 350 : 500,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2
              }}
            >
              <Typography
                id='update-modal-title'
                variant='h6'
                sx={{ mb: 2, color: 'primary.main' }}
              >
                Update Notice
              </Typography>
              <form onSubmit={handleUpdateSubmit}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id='recipient-label'>
                    Select Teacher / Student
                  </InputLabel>
                  <Select
                    labelId='recipient-label'
                    value={recipient}
                    label='Select Teacher / Student *'
                    onChange={e => setRecipient(e.target.value)}
                    required
                  >
                    <MenuItem value='ALL'>ALL</MenuItem>
                    <MenuItem value='FACULTY'>Teacher</MenuItem>
                    <MenuItem value='STUDENT'>Student</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label='Title'
                  variant='outlined'
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label='Enter Notice'
                  variant='outlined'
                  value={notice}
                  onChange={e => setNotice(e.target.value)}
                  required
                  multiline
                  rows={4}
                  sx={{ mb: 2 }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box>
                    <input
                      type='file'
                      id='file-upload-update'
                      style={{ display: 'none' }}
                      onChange={e => setFiles(e.target.files[0])}
                    />
                    <label htmlFor='file-upload-update'>
                      <Button
                        variant='contained'
                        component='span'
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload files
                      </Button>
                    </label>
                  </Box>
                  <Button type='submit' variant='contained' color='primary'>
                    Update
                  </Button>
                </Box>
              </form>
            </Box>
          </Modal>
          <Modal
            fullscreen
            open={deleteProductOpen}
            onClose={() => setDeleteProductOpen(false)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={{ ...ImgStyle, position: 'relative' }}>
              <CloseIcon
                onClick={() => setDeleteProductOpen(false)}
                style={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  cursor: 'pointer'
                }}
              />
              <h3>Are you Sure You want to delete this Notice</h3>
              <Button
                variant='contained'
                className='btn'
                onClick={() => {
                  handleDeleteProduct()
                }}
              >
                Yes
              </Button>
              <Button
                variant='contained'
                className='btn'
                onClick={() => setDeleteProductOpen(false)}
              >
                No
              </Button>
            </Box>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? 350 : 500,
                height: isMobile ? 500 : 600,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2
              }}
            >
              <Typography
                id='modal-modal-title'
                variant='h6'
                component='h2'
                sx={{ mb: 2, color: 'primary.main' }}
              >
                Add Notice
              </Typography>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id='recipient-label'>
                    Select Teacher / Student
                  </InputLabel>
                  <Select
                    labelId='recipient-label'
                    value={recipient}
                    label='Select Teacher/ Student *'
                    onChange={e => setRecipient(e.target.value)}
                    required
                  >
                    <MenuItem value='ALL'>ALL</MenuItem>
                    <MenuItem value='FACULTY'>Teacher</MenuItem>
                    <MenuItem value='STUDENT'>Student</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label='Title'
                  variant='outlined'
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                />
                <TextField
                  fullWidth
                  label='Enter Notice'
                  variant='outlined'
                  value={notice}
                  onChange={e => setNotice(e.target.value)}
                  required
                  multiline
                  rows={4}
                  sx={{ mb: 2 }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box>
                    <input
                      type='file'
                      id='file-upload'
                      style={{ display: 'none' }}
                      onChange={e => setFiles(e.target.files[0])}
                    />
                    <label htmlFor='file-upload'>
                      <Button
                        variant='contained'
                        component='span'
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload files
                      </Button>
                    </label>
                  </Box>
                  <Button type='submit' variant='contained' color='primary'>
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Modal>
          <TextField
            fullWidth
            label='Filter Notice'
            id='fullWidth'
            value={filter}
            sx={{ marginBottom: 3, marginTop: 5 }}
            onChange={e => setFilter(e.target.value)}
          />
          {filteredUsers.length === 0 ? (
            <Box sx={{ mx: 'auto' }}>
              <Box
                position='fixed'
                elevation={0}
                sx={{
                  marginTop: isMobile ? '56px' : '170px',

                  marginLeft: '-200px'
                }}
              >
                <Typography
                  variant='h4'
                  component='h1'
                  gutterBottom
                  fontWeight='bold'
                  sx={{ mt: 2, marginLeft: isMobile ? '42px' : '170px' }}
                >
                  "Education is the foundation upon which we build our future"
                  <br />
                  <Box
                    sx={{
                      marginLeft: '700px',
                      paddingTop: '-100px',
                      size: '10px'
                    }}
                    component='span'
                    color='#df7500'
                  >
                    Littlesteps
                  </Box>
                </Typography>

                <Button
                  variant='contained'
                  size='large'
                  sx={{
                    mt: 3,
                    px: 4,
                    py: 1.5,
                    borderRadius: '50px',
                    fontSize: '1.1rem',
                    marginLeft: isMobile ? '76px' : '170px',
                    backgroundColor: '#90c94d',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#005b2d' // A darker shade for hover effect
                    }
                  }}
                  onClick={handleOpen}
                >
                  MAKE YOUR FIRST NOTICE NOW
                </Button>

                {/* <Typography variant="body2" color="text.secondary" sx={{ mt: 2, marginLeft: isMobile ? "56px" : "170px", }}>
                File must be JPEG, JPG or PNG and up to 40MB
              </Typography> */}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 4,
                    mt: 3
                  }}
                >
                  {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CheckCircleIcon color="secondary" fontSize="small" />
                  <Typography variant="body2">Free to use</Typography>
                </Box> */}
                  {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CheckCircleIcon color="secondary" fontSize="small" />
                  <Typography variant="body2">No credit card required</Typography>
                </Box> */}
                </Box>

                <Typography
                  variant='body2'
                  align='center'
                  sx={{ mt: 2, marginLeft: isMobile ? '11px' : '170px' }}
                >
                  Read the terms and policies carefully{' '}
                  <Link href='#' color='primary'>
                    Terms of use
                  </Link>{' '}
                  and{' '}
                  <Link href='#' color='primary'>
                    Privacy Policy
                  </Link>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              {filteredUsers
                .filter(
                  (item, index, self) =>
                    index === self.findIndex(t => t.title === item.title)
                )
                .map(item => (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Title</Th>
                        <Th>Content</Th>
                        <Th>Recipient</Th>
                        <Th>Updated On</Th>
                        <Th>Actions</Th>
                        <Th>Details</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr key={item.id}>
                        <Td>{item.title}</Td>
                        <Td>{item.notice_text}</Td>
                        <Td>{item.recipient}</Td>
                        <Td>
                          {format(
                            new Date(item.updated_at),
                            'yyyy-MM-dd HH:mm:ss'
                          )}
                        </Td>
                        {/*<Td>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic1)}>1</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic2)}>2</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic3)}>3</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic4)}>4</Button>
                                  <Button variant="contained" className='btn' color="info" onClick={() => handleImageOpen(users.productPic5)}>5</Button>
                                </Td>*/}
                        <Td>
                          <Button
                            variant='contained'
                            className='btn'
                            onClick={() => handleUpdateOpen(item)}
                          >
                            Update
                          </Button>
                          <Button
                            variant='contained'
                            color='error'
                            className='btn'
                            onClick={e => handleAccordianAction("Delete", item)}
                          >
                            Delete
                          </Button>
                        </Td>
                        <Td>
                          <Button
                            component='a'
                            variant='contained'
                            color='success'
                            href={
                              BEBase +
                              'media/' +
                              item.syllabus +
                              `?_=${new Date().getTime()}`
                            }
                            target='_blank'
                          >
                            Check
                          </Button>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                ))}
            </Box>
          )}
        </Container>
        <Fab
          color='primary'
          aria-label='add'
          onClick={handleOpen}
          sx={{
            marginBottom: '55px',
            marginRight: '20px',
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000
          }}
        >
          {/* Add your icon here, for example: */}
          <AddIcon />
        </Fab>
      </Box>
    </ThemeProvider>
  )
}

// const NoticeManagement = () => (
//   <div className="NoticeManagement" data-testid="NoticeManagement">
//     NoticeManagement Component
//   </div>
// );

NoticeManagement.propTypes = {}

NoticeManagement.defaultProps = {}

export default NoticeManagement
