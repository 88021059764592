import './App.css'
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './components/Home/Home'
import Layout from './components/Layout/Layout'
import Admin from './components/Admin/Admin'
import Settings from './components/Settings/Settings'
import Terms from './components/Terms/Terms'
import Privacy from './components/Privacy/Privacy'
import CourseManagement from './components/CourseManagement/CourseManagement'
import SubjectManagement from './components/SubjectManagement/SubjectManagement'
import TransportManagement from './components/TransportManagement/TransportManagement'
import TeacherManagement from './components/TeacherManagement/TeacherManagement'
import TimeTableManagement from './components/TimeTableManagement/TimeTableManagement'
import TeacherTimeTable from './components/TeacherTimeTable/TeacherTimeTable'
import TeacherAttendance from './components/TeacherAttendance/TeacherAttendance'
import StudentAttendance from './components/StudentAttendance/StudentAttendance'
import StudentManagement from './components/StudentManagement/StudentManagement'
import StudentHomework from './components/StudentHomework/StudentHomework'
import FeesManagement from './components/FeesManagement/FeesManagement'
import NoticeManagement from './components/NoticeManagement/NoticeManagement'
import GalleryManagement from './components/GalleryManagement/GalleryManagement'
import ExaminationManagement from './components/ExaminationManagement/ExaminationManagement'
import ComplaintManagement from './components/ComplaintManagemnent/ComplaintManagemnent'
import ApplicationManagement from './components/ApplicationManagemnent/ApplicationManagemnent'
import EventManagement from './components/EventManagemnent/EventManagemnent'
import VideoManagement from './components/VideoManagemnent/VideoManagemnent'
import StudymaterialManagement from './components/StudymaterialManagemnent/StudymaterialManagemnent'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

const theme = createTheme({
  typography: {
    fontFamily: 'CustomFont, Arial, sans-serif', // Fallback fonts included
    h1: { fontWeight: 700 }, // Bold
    h2: { fontWeight: 600 }, // SemiBold
    body1: { fontWeight: 400 }, // Regular
    body2: { fontWeight: 300 }, // Light
    italic: { fontWeight: 400, fontStyle: 'italic' } // Italic
  }
})

function App () {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='*' element={<Home />} />
            <Route path='/add_user' element={<Admin />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/class' element={<CourseManagement />} />
            <Route path='/subject' element={<SubjectManagement />} />
            <Route path='/driver' element={<TransportManagement />} />
            <Route path='/fees' element={<FeesManagement />} />
            <Route path='/teacher' element={<TeacherManagement />} />
            <Route path='/timetable' element={<TimeTableManagement />} />
            <Route path='/teachertimetable' element={<TeacherTimeTable />} />
            <Route path='/teacherAttendance' element={<TeacherAttendance />} />
            <Route path='/student' element={<StudentManagement />} />
            <Route path='/studentAttendance' element={<StudentAttendance />} />
            <Route path='/studentHomework' element={<StudentHomework />} />
            <Route path='/noticeManagement' element={<NoticeManagement />} />
            <Route path='/galleryManagement' element={<GalleryManagement />} />
            <Route
              path='/examinationManagement'
              element={<ExaminationManagement />}
            />
            <Route
              path='/complaintManagement'
              element={<ComplaintManagement />}
            />
            <Route
              path='/applicationManagement'
              element={<ApplicationManagement />}
            />
            <Route path='/eventManagement' element={<EventManagement />} />
            <Route path='/videoManagement' element={<VideoManagement />} />
            <Route
              path='/studymaterialManagement'
              element={<StudymaterialManagement />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
