import React from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  CardActions,
  CardContent,
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAuth } from '../../services/authContext'
import { RegisterApi, ForgetPassscodeApi } from '../../services/apis'
import Grid from '@mui/material/Grid'
import { Select, MenuItem } from '@mui/material'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
    // Add more theme customizations as needed
  }
})

const SignupForm = () => {
  const { loginStatus, setLoginStatus } = useAuth()

  // State variables for form fields
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [dob, setDob] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [staffType, setStaffType] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [schoolName, setSchoolName] = React.useState('')
  const [loading, setLoading] = React.useState(false) // Track loading status for signup

  // Function to handle signup (creating a new account)
  const handleSignup = async () => {
    setLoading(true)
    setTimeout(() => {
      // Handle signup logic after delay
      // Example: submit the form, etc.
      setLoading(false) // End loading after 2 seconds
    }, 2000) // 2000 milliseconds = 2 seconds

    // Collect user data for signup

    const userData = {
      phone: phoneNumber,
      username: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      school_name: schoolName,
      email,
      address,
      dob,
      gender,
      status: 'active',
      staff_type: 'admin',
      password:
        firstName.toUpperCase() +
        lastName.toLowerCase() +
        Math.random() * 999999,
      date_joined: new Date(),
      is_superuser: true
    }

    // Call the API for signup
    RegisterApi(userData)
      .then(response => {
        ForgetPassscodeApi({ phone: response.user.phone })
          .then(response => {
            alert('OTP sent successfully!')
            setLoading(false) // Stop loading indicator
          })
          .catch(error => {
            alert('Error sending OTP. Please try again.')
            setLoading(false) // Stop loading indicator on error
          })
        setLoading(false)
      })
      .catch(error => {
        alert('Signup failed. Please try again.')
        setLoading(false)
      })
  }

  const SignupCard = (
    <React.Fragment>
      <Card
        sx={{
          backgroundColor: 'white',
          boxShadow: 3,
          borderRadius: 2,
          padding: 2
        }}
      >
        <CardContent
          sx={{
            maxHeight: 'calc(100vh - 200px)', // Adjust the height depending on your layout
            overflowY: 'auto', // Enable vertical scrolling
            overflowX: 'hidden', // Hide horizontal overflow
            paddingBottom: '16px',
            backgroundColor: 'white' // Ensure there's some padding at the bottom
          }}
        >
          <Typography
            variant='h5'
            component='h4'
            sx={{
              color: theme.palette.primary.main,
              textAlign: 'center',
              marginBottom: 3,
              fontWeight: 'bold'
            }}
          >
            Sign Up to Join the Platform
          </Typography>

          <Grid container rowSpacing={2} columnSpacing={3}>
            {' '}
            {/* Responsive column spacing */}
            {/* Phone Number */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Phone Number
              </label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='Phone Number'
                fullWidth
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </Grid>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                First Name
              </label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='First Name'
                fullWidth
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Last Name
              </label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='Last Name'
                fullWidth
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Grid>
            {/* Email Address */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Email Address
              </label>
              <TextField
                variant='outlined'
                size='small'
                type='email'
                placeholder='Email Address'
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
            {/* Address */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Address
              </label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='Address'
                fullWidth
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </Grid>
            {/* Date of Birth */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Date of Birth
              </label>
              <TextField
                variant='outlined'
                size='small'
                type='date'
                fullWidth
                value={dob}
                onChange={e => setDob(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            {/* Gender */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Gender
              </label>
              <Select
                value={gender}
                onChange={e => setGender(e.target.value)}
                fullWidth
                size='small'
                sx={{ backgroundColor: 'white' }}
              >
                {/* Placeholder as default option */}
                <MenuItem value='' disabled>
                  Select Gender
                </MenuItem>

                {/* Gender Options */}
                <MenuItem style={{ color: 'black' }} value='male'>
                  Male
                </MenuItem>
                <MenuItem style={{ color: 'black' }} value='female'>
                  Female
                </MenuItem>
                <MenuItem style={{ color: 'black' }} value='others'>
                  Others
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                School Name
              </label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='School Name'
                style={{
                  color: 'black', // For regular text color
                  backgroundColor: 'white' // Ensure contrast
                }}
                fullWidth
                value={schoolName}
                onChange={e => setSchoolName(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* Signup Form Submit Button */}
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSignup}
              disabled={loading}
              sx={{
                color: 'black', // Set the text color to black
                bgcolor: '#eda811', // Set the background color
                '&:hover': {
                  bgcolor: 'grey' // Set the background color to grey on hover
                }
              }}
            >
              {loading ? <CircularProgress size={24} /> : 'Sign Up'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  )

  return (
    <ThemeProvider theme={theme}>
      <div className='SignupForm' data-testid='SignupForm'>
        <Box
          sx={{
            minWidth: 275,
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
          className='perfect-center'
        >
          <Card variant='outlined'>{SignupCard}</Card>
        </Box>
      </div>
    </ThemeProvider>
  )
}

SignupForm.propTypes = {}

export default SignupForm
