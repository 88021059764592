import React from 'react'
import PropTypes from 'prop-types'
import './TeacherDashboard.scss'
import { Grid, Box, Card, CardContent, Typography } from '@mui/material'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import { Link } from 'react-router-dom'
import {
  ListProductApi,
  ListSubjectApi,
  ListFeesApi,
  ListTeachersApi,
  ListStudentsApiTeacher
} from '../../services/apis'
import { useAuth } from '../../services/authContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PersonIcon from '@mui/icons-material/Person'
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl'
import SchoolIcon from '@mui/icons-material/School'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import EventNoteIcon from '@mui/icons-material/EventNote'
import PunchClockIcon from '@mui/icons-material/PunchClock'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CollectionsIcon from '@mui/icons-material/Collections'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import GppBadIcon from '@mui/icons-material/GppBad'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import CelebrationIcon from '@mui/icons-material/Celebration'
import VideocamIcon from '@mui/icons-material/Videocam'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import BadgeIcon from '@mui/icons-material/Badge';
import Slider from 'react-slick';
import { BEBase } from '../../constants/app.constant';

const TeacherDashboard = () => {
  const { loginStatus, setLoginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [listOfSubjects, setListOfSubjects] = React.useState([])
  const [listOfFees, setListOfFees] = React.useState([])
  const [listOfTeacher, setListOfTeacher] = React.useState([])
  const [listOfStudent, setListOfStudent] = React.useState([])

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: { main: '#e4b648' },
      warning: { main: '#e4b648' },
      background: {
        default: '#f5f5f5', // Light grey background
        paper: '#e0e0e0' // Slightly darker grey for paper components
      }
    }
  });

  const carouselItems = [
    {
      image: BEBase + "apimedia/apimedia/imgs/1021258539111836_1730212292.jpg"
    },
    {
      image: BEBase + "apimedia/apimedia/imgs/1021258539111836_1730212292.jpg"
    },
    {
      image: BEBase + "apimedia/apimedia/imgs/1021258539111836_1730212292.jpg"
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };


  React.useEffect(() => {
    const loginStat = localStorage.getItem('loginCred')
    if (loginStat) {
      const parsedLoginStat = JSON.parse(loginStat)
      setLoginStatus(parsedLoginStat)

      const fetchListStudent = async () => {
        try {
          const response = await ListStudentsApiTeacher({
            id: parsedLoginStat.user.id
          })
          setListOfStudent(response)
          console.log(listOfTeacher)
        } catch (error) {
          console.error('Error fetching Teacher:', error)
        }
      }

      fetchListStudent()
    }
  }, [setLoginStatus]) // Only depend on setLoginStatus

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          backgroundColor: 'rgba(213, 213, 213, 1)',
          marginBottom: '57px',
          paddingBottom: '80px'
        }}
        className='TeacherDashboard'
        data-testid='TeacherDashboard'
      >
        {/* Carousel */}
        <Box sx={{ flexGrow: 1, marginBottom: '20px' }}>
          <Slider {...settings}>
            {carouselItems.map((item, index) => (
              <div key={index}>
                <Box
                  sx={{
                    position: 'relative',
                    textAlign: 'center',
                    backgroundImage: `url(${item.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: '#fff'
                  }}
                >
                  <Typography
                    variant='h4'
                    sx={{  marginBottom: '10px' }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant='subtitle1'>{item.subtitle}</Typography>
                </Box>
              </div>
            ))}
          </Slider>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant='h6'  
            component='h6'
            sx={{
              textAlign: 'left',
              color: '#2c3e50',
              padding: '10px 20px',
              fontWeight: '600',
              letterSpacing: '1px',
              borderBottom: '2px solid #bdc3c7'
            }}
          >
            Student Section
          </Typography>

          <Grid
            container
            
            spacing={1}
            sx={{ marginTop: '8px' }}
          >
            {/* Teacher Grid */}
            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/student'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <PersonIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#0075FF'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',
                        
                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfStudent.length}{' '}
                      {listOfStudent.length > 1 ? 'Students' : 'Student'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* Student Attendance Grid */}
            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/studentAttendance'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <EventNoteIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#EE3A7C'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',
                        
                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1
                        ? 'Students Attendance'
                        : 'Student Attendance'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, marginBottom: '-40px' }}>
          <Typography
            variant='h6'
            component='h6'
            sx={{
              textAlign: 'left',
              color: '#2c3e50',
              padding: '10px 20px',
              fontWeight: '600',
              letterSpacing: '1px',
              borderBottom: '2px solid #bdc3c7'
            }}
          >
            Miscellaneous Section
          </Typography>

          <Grid
            container
            sx={{ marginTop: '8px' }}
            spacing={1}
            rowSpacing={1}
          >
            {/* notification Grid */}
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <Link
                to='/noticeManagement'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <NotificationsIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#d2372d'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',

                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1 ? 'Notices' : 'Notice'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* gallery Grid */}
            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/galleryManagement'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <CollectionsIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#28a824'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',

                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1 ? 'Gallery' : 'Gallery'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* examinationManagement  grid */}

            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/examinationManagement'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <ContentPasteSearchIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#007e7d'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',

                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1
                        ? 'Examinations'
                        : 'Examination'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* complaint grid */}

            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/ComplaintManagement'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <GppBadIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#f10000'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',

                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1 ? 'Complaints' : 'Complaint'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* Application grid */}

            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/ApplicationManagement'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <MarkEmailReadIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#ffab13'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',

                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1
                        ? 'Applications'
                        : 'Application'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            {/* video grid */}

            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/VideoManagement'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <VideocamIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#7e00d5'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',

                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1 ? 'Videos' : 'Video'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* study material grid */}

            <Grid
              item
              xs={4}
              sm={6}
              md={4}
              lg={3}
              sx={{ order: { xs: 1, sm: 1, md: 4 } }}
            >
              <Link
                to='/StudymaterialManagement'
                className='linkTag'
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    backgroundColor: '#fcfcfc',
                    color: '#000000',
                    textAlign: 'center',
                    padding: '20px',
                    ':hover': { backgroundColor: '#e9eaeb' },

                    transition: 'all 0.3s ease-in-out',
                    aspectRatio: { xs: '1/1', sm: 'auto' }
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      height: '100%',
                      padding: { xs: '20px', sm: '20px' }
                    }}
                  >
                    <Typography variant='h5'>
                      <AutoStoriesIcon
                        sx={{
                          fontSize: { xs: 35, sm: 50, md: 70 },
                          color: '#ea4b04'
                        }}
                      />
                    </Typography>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: '10px',

                        fontSize: { xs: '14px', sm: '16px' }
                      }}
                    >
                      {listOfTeacher.length}{' '}
                      {listOfTeacher.length > 1
                        ? 'Studymaterials'
                        : 'Studymaterial'}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* more grid if you want */}
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  )
}

TeacherDashboard.propTypes = {}

export default TeacherDashboard
