import React from 'react'
import PropTypes from 'prop-types'
import './LoginForm.scss'
import {
  useMediaQuery,
  Card,
  CardActions,
  CardContent,
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
  Link
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useAuth } from '../../services/authContext'
import { LoginApi, ForgetPassscodeApi } from '../../services/apis' // Import the API for passcode verification

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
    // Add more theme customizations as needed
  }
})

const LoginForm = () => {
  const { loginStatus, setLoginStatus } = useAuth()
  const [phoneNumber, setPhoneNumber] = React.useState('') // Phone number input
  const [passcode, setPasscode] = React.useState('') // Passcode input by user
  const [loading, setLoading] = React.useState(false) // Track loading status for login
  const [forgetPass, setForgetPass] = React.useState(false)
  // Function to handle login (verify passcode)
  const handleLogin = () => {
    setLoading(true) // Start loading indicator
    // Send login request with phone number and passcode
    LoginApi({ phone: phoneNumber, passcode: passcode }) // Treat passcode as password
      .then(response => {
        localStorage.setItem('loginCred', JSON.stringify(response))
        setLoginStatus(response) // Update login status in context
        setLoading(false) // Stop loading indicator
      })
      .catch(error => {
        alert('Invalid passcode. Please try again.')
        setLoading(false) // Stop loading indicator on error
      })
  }

  const handleForgetPasscode = () => {
    setLoading(true) // Start loading indicator
    ForgetPassscodeApi({ phone: phoneNumber })
      .then(response => {
        alert('OTP sent successfully!')
        setLoading(false) // Stop loading indicator
      })
      .catch(error => {
        alert('Error sending OTP. Please try again.')
        setLoading(false) // Stop loading indicator on error
      })
  }

  const handleForgetPasscodeSetup = () => {
    setForgetPass(true) // Start loading indicator
  }

  React.useEffect(() => {
    const loginStat = localStorage.getItem('loginCred')
    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat))
    }
  }, [setLoginStatus])

  const LoginCard = (
    <React.Fragment>
      <CardContent>
        <Typography
          variant='h5'
          component='h4'
          sx={{ color: theme.palette.primary.main }}
        >
          Already Enrolled? Login Below to Book Classes, Check Attendance,
          Recordings, and More
        </Typography>
        <Typography variant='h6' component='div'>
          <Box
            sx={{ flexGrow: 1, mx: 2, textAlign: 'left', marginTop: '30px' }}
          >
            <label>Phone Number</label>
            <TextField
              variant='outlined'
              size='small'
              placeholder='Phone Number'
              fullWidth
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Box>
        </Typography>
        {!forgetPass ? (
          <Typography variant='h6' component='div'>
            <Box
              sx={{ flexGrow: 1, mx: 2, textAlign: 'left', marginTop: '15px' }}
            >
              <label>Enter Passcode</label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='Passcode'
                fullWidth
                value={passcode}
                onChange={e => setPasscode(e.target.value)}
              />
            </Box>
          </Typography>
        ) : (
          <></>
        )}

        {/* Forget Passcode Link */}
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link
            onClick={handleForgetPasscodeSetup}
            variant='body2'
            sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
          >
            Forget Passcode?
          </Link>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          flexGrow: 1,
          mx: 2,
          justifyContent: 'center',
          display: 'flex',
          mb: 2
        }}
      >
        <Button
          variant='contained'
          className='btn'
          onClick={!forgetPass ? handleLogin : handleForgetPasscode}
          disabled={loading} // Disable button if loading
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: '#fff' }} />
          ) : (
            <>{!forgetPass ? 'Login Now' : 'Get Passcode'}</>
          )}{' '}
          {/* Show loader or text */}
        </Button>
      </CardActions>
    </React.Fragment>
  )

  return (
    <div className='LoginForm' data-testid='LoginForm'>
      <Box
        sx={{
          minWidth: 275,
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
        className='perfect-center'
      >
        <Card variant='outlined'>{LoginCard}</Card>
      </Box>
    </div>
  )
}

LoginForm.propTypes = {}

export default LoginForm
