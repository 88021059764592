import React from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../../services/authContext'
import {
  Container,
  Box,
  Fab,
  TextField,
  Button,
  Modal,
  Typography,
  Divider,
  List,
  ListItem,
  Autocomplete,
  Stack,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  ListSubjectApi,
  ListProductApi,
  CreateTaskApi,
  ListTeachersApi,
  CreateSubjectApi,
  DeleteSubjectApi,
  UpdateCourseImageApi,
  UpdateSubjectApi,
  CreateTeacherApi,
  UpdateTeacherApi,
  DeleteTeacherApi,
  ForgetPassscodeApi
} from '../../services/apis'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import { BEBase } from '../../constants/app.constant'
import './TeacherManagement.scss'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
    // Add more theme customizations as needed
  }
})

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vW',
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '240px'
}

const UpdateStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vW',
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH'
}

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%', // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '240px',
  '@media (max-width: 600px)': {
    // Adjust the breakpoint as needed
    width: '100%' // Width for mobile devices
  }
}

const TeacherManagement = () => {
  const { loginStatus, setLoginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState('')
  const [heading, setHeading] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [amount, setAmount] = React.useState('')
  const [paymentLink, setPaymentLink] = React.useState('')
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [className, setclassName] = React.useState('')
  const [classList, setCLassList] = React.useState([])
  const [subjectName, setsubjectNamen] = React.useState('')
  const [subjectCode, setsubjectCode] = React.useState('')
  const [subjectType, setsubjectType] = React.useState('')
  const [discountedPrice, setDiscountedPrice] = React.useState('')
  const [productPic1, setProductPic1] = React.useState()
  const [productPic2, setProductPic2] = React.useState()
  const [productPic3, setProductPic3] = React.useState()
  const [productPic4, setProductPic4] = React.useState()
  const [productPic5, setProductPic5] = React.useState()
  const [imgOpen, setImgOpen] = React.useState(false)
  const [selectedID, setSelectedID] = React.useState('')
  const [openedImage, setOpenedImage] = React.useState('')
  const [syllabus, setSyllabus] = React.useState()
  const [changeImgFlag, setChangeImgFlag] = React.useState('')
  const [imageCacheKey, setImageCacheKey] = React.useState(Date.now())
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [dob, setDob] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [status, setStatus] = React.useState('Active')
  const [dateJoined, setDateJoined] = React.useState(Date.now())

  React.useEffect(() => {
    const loginStat = localStorage.getItem('loginCred')
    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat))
    }
    if (listApiFlag && loginStatus.user && loginStatus.user.is_superuser) {
      const fetchListProducts = async () => {
        try {
          const authToken = localStorage.getItem('loginCred')
          const response = await ListTeachersApi(
            JSON.stringify({ adminToken: JSON.parse(authToken).token })
          )
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      const fetchListClasses = async () => {
        try {
          const response = await ListProductApi()
          response.forEach(element => {
            element.title = element.productName
          })
          console.log('getresponse', JSON.stringify(response))
          setCLassList(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListProducts()
      fetchListClasses()
    }
  }, [loginStatus])

  const handleUpdateTask = async () => {
    try {
      const formData = JSON.stringify({
        item_id: selectedID,
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        phone: phone,
        address: address,
        dob: new Date(dob).toISOString().split('T')[0],
        gender: gender,
        status: status,
        date_joined: dateJoined
      })

      const response = await UpdateTeacherApi(formData)
      console.log('Teacher created successfully:', response)
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating teacher:', error)
    }
  }

  const handleDeleteProductModal = async item_id => {
    setDeleteProductOpen(true)
    setSelectedID(item_id)
  }

  const filteredUsers = listOfUsers.filter(
    user =>
      user.first_name.toLowerCase().includes(filter.toLowerCase()) ||
      user.last_name.toLowerCase().includes(filter.toLowerCase())
  )

  const uniqueFilteredUsers = new Set(filteredUsers)

  const [open, setOpen] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)
  const handleOpen = username => {
    console.log('yo yo usrname', username)
    setSelectedID(username.id)
    setFirstName(username.first_name)
    setLastName(username.last_name)
    setEmail(username.email)
    setAddress(username.address)
    setPhone(username.phone)
    setDateJoined(username.date_joined)
    setDob(username.dob)
    setGender(username.gender)
    setPassword(username.otp)
    setStatus(username.status)
    setOpen(true)
  }

  const handleOpenCreate = async () => {
    const today = new Date()
    const formattedDate = today.toISOString().split('T')[0]
    setDateJoined(formattedDate)
    setOpenCreate(true)
  }

  const handleImageOpen = path => {
    setOpenedImage(path)
    setImgOpen(true)
    setImageCacheKey(Date.now())
  }
  const handleClose = () => {
    setOpen(false)
    setOpenCreate(false)
  }

  const handleCreateTeacher = async () => {
    try {
      const authToken = localStorage.getItem('loginCred')
      const formData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: '1234',
        staff_type: 'teacher',
        phone: phone,
        address: address,
        dob: new Date(dob).toISOString().split('T')[0],
        gender: gender,
        status: status,
        date_joined: dateJoined,
        adminToken: JSON.parse(authToken).token
      }

      const response = await CreateTeacherApi(formData)
      console.log('Teacher created successfully:', response)
      ForgetPassscodeApi({ phone })
        .then(response => {
          alert('OTP sent successfully!')
          window.location.reload()
        })
        .catch(error => {
          alert('Error sending OTP. Please try again.')
        })
      handleClose()
    } catch (error) {
      console.error('Error creating teacher:', error)
    }
  }

  const handleUpdateImageTask = async imagePath => {
    console.log(imagePath)
    const formData = new FormData()
    formData.append('changeImgFlag', changeImgFlag)
    formData.append('imagePath', imagePath)
    var response = await UpdateCourseImageApi(formData)
  }

  const handleDeleteProduct = async deleteId => {
    try {
      const formData = new FormData()
      formData.append('id', deleteId)

      // Assuming DeleteCourseApi expects formData
      const response = await DeleteTeacherApi(formData)

      console.log('Product deleted successfully:', response)
      handleClose()
    } catch (error) {
      console.error('Error deleting Product:', error)
    }
  }

  return (
    <div className='TeacherManagement' data-testid='TeacherManagement'>
      <ThemeProvider theme={theme}>
        <Box
          className='CourseManagement'
          data-testid='CourseManagement'
          sx={{
            padding: '90px 0px',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh'
          }}
        >
          <Container maxWidth='lg' sx={{ flexGrow: 1 }}>
            {loginStatus.token && (
              <Box>
                {loginStatus.user.is_superuser ? (
                  <div className='table-cover'>
                    <Box sx={{ position: 'absolute', bottom: 76, right: 16 }}>
                      <Fab
                        color='primary'
                        aria-label='add'
                        onClick={handleOpenCreate}
                        sx={{
                          position: 'fixed',
                          right: '30px',
                          bottom: '70px'
                        }}
                      >
                        <AddIcon />
                      </Fab>
                    </Box>
                    <TextField
                      fullWidth
                      label='Filter Class'
                      id='fullWidth'
                      value={filter}
                      sx={{ marginBottom: 3 }}
                      onChange={e => setFilter(e.target.value)}
                    />
                    {filteredUsers
                      .filter(
                        (item, index, self) =>
                          index ===
                          self.findIndex(t => t.className === item.className)
                      )
                      .map(item => (
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1-content'
                            id='panel1-header'
                          >
                            List Of Teachers
                          </AccordionSummary>
                          <AccordionDetails>
                            <Table>
                              <Thead>
                                <Tr>
                                  <Th>Teacher Name</Th>
                                  <Th>Teacher Phone</Th>
                                  {/*<Th>Class Pics</Th>*/}
                                  <Th>Actions</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {filteredUsers.map(users => (
                                  <>
                                    {users.className === item.className ? (
                                      <Tr key={users.id}>
                                        <Td>
                                          {users.first_name} {users.last_name}
                                        </Td>
                                        <Td>{users.phone}</Td>
                                        <Td>
                                          <Button
                                            variant='contained'
                                            className='btn'
                                            onClick={() => handleOpen(users)}
                                          >
                                            Update
                                          </Button>
                                          <Button
                                            variant='contained'
                                            color='error'
                                            className='btn'
                                            onClick={() =>
                                              handleDeleteProductModal(users.id)
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </Td>
                                      </Tr>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                              </Tbody>
                            </Table>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    <Modal
fullscreen
                      open={deleteProductOpen}
                      onClose={() => setDeleteProductOpen(false)}
                      aria-labelledby='modal-modal-title'
                      aria-describedby='modal-modal-description'
                    >
                      <Box sx={{ ...ImgStyle, position: 'relative' }}>
                        <CloseIcon
                          onClick={() => setDeleteProductOpen(false)}
                          style={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            cursor: 'pointer'
                          }}
                        />
                        <h3>Are you Sure You want to delete this Class</h3>
                        <Button
                          variant='contained'
                          className='btn'
                          onClick={() => {
                            handleDeleteProduct(selectedID)
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          variant='contained'
                          className='btn'
                          onClick={() => setDeleteProductOpen(false)}
                        >
                          No
                        </Button>
                      </Box>
                    </Modal>
                    <Modal
fullscreen
                      open={open}
                      onClose={handleClose}
                      aria-labelledby='modal-modal-title'
                      aria-describedby='modal-modal-description'
                    >
                      <Box sx={{ ...UpdateStyle, position: 'relative' }}>
                        <CloseIcon
                          onClick={handleClose}
                          style={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            cursor: 'pointer'
                          }}
                        />
                        <Typography
                          id='modal-modal-title'
                          variant='h6'
                          component='h2'
                          sx={{ marginBottom: 3 }}
                        >
                          Update Teacher
                        </Typography>

                        {/* Teacher Info Fields */}
                        <TextField
                          fullWidth
                          required
                          label='First Name'
                          value={firstName}
                          onChange={e => setFirstName(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          required
                          label='Last Name'
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          required
                          type='email'
                          label='Email'
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          required
                          label='Phone'
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          label='Address'
                          value={address}
                          onChange={e => setAddress(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          required
                          label='Date of Birth'
                          type='date'
                          value={dob}
                          onChange={e => setDob(e.target.value)}
                          sx={{ marginBottom: 3 }}
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          fullWidth
                          label='Date of Joining'
                          type='date'
                          value={dateJoined}
                          onChange={e => setDateJoined(e.target.value)}
                          sx={{ marginBottom: 3 }}
                          InputLabelProps={{ shrink: true }}
                        />
                        <Autocomplete
                          id='gender-select'
                          value={gender}
                          options={['Male', 'Female', 'Other']}
                          onChange={(event, newValue) => setGender(newValue)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Select Gender'
                              fullWidth
                            />
                          )}
                          sx={{ marginBottom: 3 }}
                        />
                        <Autocomplete
                          required
                          value={status}
                          id='status-select'
                          options={['Active', 'Inactive']}
                          onChange={(event, newValue) => setStatus(newValue)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Select Status *'
                              fullWidth
                            />
                          )}
                          sx={{ marginBottom: 3 }}
                        />

                        <Button
                          variant='contained'
                          className='btn'
                          onClick={handleUpdateTask}
                        >
                          Update Teacher
                        </Button>
                      </Box>
                    </Modal>
                    <Modal
fullscreen
                      open={imgOpen}
                      onClose={() => setImgOpen(false)}
                      aria-labelledby='modal-modal-title'
                      aria-describedby='modal-modal-description'
                    >
                      <Box sx={{ ...ImgStyle, position: 'relative' }}>
                        <CloseIcon
                          onClick={() => setImgOpen(false)}
                          style={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            cursor: 'pointer'
                          }}
                        />
                        <Box>
                          <img
                            src={`${BEBase}media/${openedImage}?cache=${imageCacheKey}`}
                            style={{ width: '100%' }}
                          />
                        </Box>
                        <TextField
                          fullWidth
                          required
                          className='register-form'
                          type='file'
                          sx={{ marginBottom: 3 }}
                          onChange={e => {
                            const file = e.target.files[0]
                            if (file) {
                              setChangeImgFlag(file)
                            }
                          }}
                        />
                        <Button
                          variant='contained'
                          className='btn'
                          onClick={() => {
                            handleUpdateImageTask(openedImage)
                            setImgOpen(false) // Close the modal after the update
                          }}
                        >
                          Change Image
                        </Button>
                      </Box>
                    </Modal>
                    <Modal
fullscreen
                      open={openCreate}
                      onClose={handleClose}
                      aria-labelledby='modal-modal-title'
                      aria-describedby='modal-modal-description'
                      sx={{ overflow: 'auto' }}
                    >
                      <Box sx={{ ...style, position: 'relative' }}>
                        <CloseIcon
                          onClick={handleClose}
                          style={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            cursor: 'pointer'
                          }}
                        />
                        <Typography
                          id='modal-modal-title'
                          variant='h6'
                          component='h2'
                          sx={{ marginBottom: 3 }}
                        >
                          Create New Teacher
                        </Typography>

                        {/* Teacher Info Fields */}
                        <TextField
                          fullWidth
                          required
                          label='First Name'
                          value={firstName}
                          onChange={e => setFirstName(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          required
                          label='Last Name'
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          required
                          type='email'
                          label='Email'
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          required
                          label='Phone'
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          label='Address'
                          value={address}
                          onChange={e => setAddress(e.target.value)}
                          sx={{ marginBottom: 3 }}
                        />
                        <TextField
                          fullWidth
                          required
                          label='Date of Birth'
                          type='date'
                          value={dob}
                          onChange={e => setDob(e.target.value)}
                          sx={{ marginBottom: 3 }}
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          fullWidth
                          label='Date of Joining'
                          type='date'
                          value={dateJoined}
                          onChange={e => setDateJoined(e.target.value)}
                          sx={{ marginBottom: 3 }}
                          InputLabelProps={{ shrink: true }}
                        />
                        <Autocomplete
                          id='gender-select'
                          options={['Male', 'Female', 'Other']}
                          onChange={(event, newValue) => setGender(newValue)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Select Gender'
                              fullWidth
                            />
                          )}
                          sx={{ marginBottom: 3 }}
                        />
                        <Autocomplete
                          required
                          id='status-select'
                          options={['Active', 'Inactive']}
                          onChange={(event, newValue) => setStatus(newValue)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Select Status *'
                              fullWidth
                            />
                          )}
                          sx={{ marginBottom: 3 }}
                        />

                        <Button
                          variant='contained'
                          className='btn'
                          onClick={handleCreateTeacher}
                          disabled={!firstName || !lastName || !phone || !email}
                        >
                          Create Teacher
                        </Button>
                      </Box>
                    </Modal>
                  </div>
                ) : (
                  <div className='task-cover'>
                    {filteredUsers.map(item => (
                      <>
                        <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                        <List sx={{ width: '100%', bgcolor: 'secondary' }}>
                          <ListItem alignItems='flex-start'>
                            <ListItemText
                              primary={item.taskHeading}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component='span'
                                    variant='body2'
                                    sx={{
                                      color: 'text.primary',
                                      display: 'inline'
                                    }}
                                  >
                                    {item.taskDescription}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          {item.amount > 0 ? (
                            <Button component='a' href={item.paymentLink}>
                              Pay Rs. {item.amount}
                            </Button>
                          ) : (
                            <></>
                          )}
                        </List>
                        <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                      </>
                    ))}
                  </div>
                )}
              </Box>
            )}
          </Container>
        </Box>
      </ThemeProvider>
    </div>
  )
}

TeacherManagement.propTypes = {}

TeacherManagement.defaultProps = {}

export default TeacherManagement
