import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import './TeacherAttendance.scss';
import {
  Container,
  Box,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../../services/authContext';
import {
  ListTeachersApi,
  TeacherAttendanceApi,
  ListTeacherAttendanceApi,
} from '../../services/apis';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' },
  },
});

const TeacherAttendance = () => {
  const { loginStatus, setLoginStatus } = useAuth();
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [listApiFlag, setListApiFlag] = React.useState(true);
  const [listTeacherAttendance, setListTeacherAttendance] = React.useState([]);
  const [loading, setLoading] = React.useState(false); // Loading state

  React.useEffect(() => {
    const loginStat = localStorage.getItem('loginCred');
    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat));
    }

    if (listApiFlag && loginStatus.user && loginStatus.user.is_superuser) {
      const fetchListTeachers = async () => {
        try {
          const authToken = localStorage.getItem('loginCred')
          const response = await ListTeachersApi(
            JSON.stringify({ adminToken: JSON.parse(authToken).token })
          )
          setListOfUsers(response);
          setListApiFlag(false);
        } catch (error) {
          console.error('Error fetching users:', error);
          setListApiFlag(false);
        }
      };

      fetchListTeachers();
    }
  }, [loginStatus, listApiFlag]);

  // Fetch teacher attendance data when the selected date changes
  React.useEffect(() => {
    const fetchListTeachersAttendance = async () => {
      setLoading(true); // Start loading
      try {
        const formattedDate = { date: selectedDate.format('YYYY-MM-DD') };
        const response = await ListTeacherAttendanceApi(formattedDate);
        setListTeacherAttendance(response);
      } catch (error) {
        console.error('Error fetching attendance:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (selectedDate) {
      fetchListTeachersAttendance();
    }
  }, [selectedDate]);

  const handleAttendanceClick = async (attendance, teacherId) => {
    const formattedDate = selectedDate.format('YYYY-MM-DD');
    const attendanceData = JSON.stringify({
      attendance,
      teacher_id: teacherId,
      date: formattedDate,
    });

    try {
      const response = await TeacherAttendanceApi(attendanceData);
      if (response) {
        setListTeacherAttendance((prevState) => {
          const safePrevState = Array.isArray(prevState) ? prevState : [];
          const updatedList = safePrevState.map((item) =>
            item.teacher === teacherId
              ? { ...item, attendance: response.task.attendance }
              : item
          );

          if (!safePrevState.some((item) => item.teacher === teacherId)) {
            updatedList.push({
              teacher: teacherId,
              attendance: response.task.attendance,
            });
          }

          return updatedList;
        });
      }
    } catch (error) {
      console.error('Error updating attendance:', error);
    }
  };

  const getAttendanceStatus = (teacherId) => {
    const attendance = listTeacherAttendance.find(
      (attendance) => attendance.teacher === teacherId
    );
    return attendance ? attendance.attendance : null;
  };

  return (
    <div className="TeacherManagement" data-testid="TeacherManagement">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            className="CourseManagement"
            sx={{
              padding: '90px 0px',
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
            }}
          >
            <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
              {loginStatus.token && (
                <Box>
                  {loginStatus.user.is_superuser && (
                    <div className="table-cover">
                      {/* Date Picker */}
                      <DatePicker
                        label="Select Date"
                        fullWidth
                        value={selectedDate}
                        onChange={(newValue) => setSelectedDate(dayjs(newValue))}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ marginBottom: 3, width: '100%' }}
                          />
                        )}
                      />

                      {/* Show loader while loading */}
                      {loading ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '300px',
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          {/* Filter Input */}
                          <TextField
                            fullWidth
                            label="Filter Teachers"
                            id="filter-teachers"
                            value={filter}
                            sx={{ marginBottom: 3 }}
                            onChange={(e) => setFilter(e.target.value)}
                          />

                          {/* Teacher Attendance List */}
                          {listOfUsers.map((item) => {
                            const attendanceStatus = getAttendanceStatus(
                              item.id
                            );
                            return (
                              <Accordion
                                defaultExpanded
                                key={item.id}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <div>
                                    {item.first_name} {item.last_name}
                                    <br />
                                    <small style={{ color: '#666' }}>
                                      {item.email} ({item.phone})
                                    </small>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-around" }}>
                                  <Button

                                    variant={attendanceStatus === 'Present' ? 'contained' : 'outlined'}
                                    disabled={attendanceStatus === 'Present'}
                                    onClick={() => handleAttendanceClick('Present', item.id)}
                                    sx={{ width: "100px", height: "40px" }}
                                  >
                                    Present
                                  </Button>
                                  <Button
                                    variant={attendanceStatus === 'Absent' ? 'contained' : 'outlined'}
                                    disabled={attendanceStatus === 'Absent'}
                                    onClick={() => handleAttendanceClick('Absent', item.id)}
                                    sx={{ width: "100px", height: "40px" }}
                                  >
                                    Absent
                                  </Button>
                                  <Button
                                    variant={attendanceStatus === 'Leave' ? 'contained' : 'outlined'}
                                    disabled={attendanceStatus === 'Leave'}
                                    onClick={() => handleAttendanceClick('Leave', item.id)}
                                    sx={{ width: "100px", height: "40px" }}
                                  >
                                    Leave
                                  </Button>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </>
                      )}
                    </div>
                  )}
                </Box>
              )}
            </Container>
          </Box>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
};

TeacherAttendance.propTypes = {};
TeacherAttendance.defaultProps = {};

export default TeacherAttendance;
