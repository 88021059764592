// import React from 'react';
// import PropTypes from 'prop-types';
// import './ComplaintManagemnent.scss';
// import { TextField, Button, Container, Typography, Box, useMediaQuery, Fab, Modal} from "@mui/material";
// import { ThemeProvider, createTheme, } from '@mui/material/styles';
// import AddIcon from '@mui/icons-material/Add';

// // const ComplaintManagemnent = () => (
// //   <div className="ComplaintManagemnent" data-testid="ComplaintManagemnent">
// //     ComplaintManagemnent Component
// //   </div>
// // );




// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#284d84', // blue color
//     },
//     secondary: {
//       main: '#EC4899', // Pink color for the check icons
//     },
//     typography: {
//       fontFamily: 'Arial, sans-serif',
//     },
//     components: {
//       MuiButton: {
//         styleOverrides: {
//           root: {
//             textTransform: 'none',
//           },
//         },
//       },
//     },
//   },
// });


// function ComplaintManagemnent() {
//   const [reason, setReason] = React.useState("");
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [open, setOpen] = React.useState(false); // State to manage modal visibility


//   const handleFabClick = (event) => {
//     setAnchorEl(event.currentTarget);
//     setOpen((prevOpen) => !prevOpen);
//   };


//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Complaint Reason Submitted:", reason);
//     // Add your logic to process the complaint reason here
//     console.log(reason);
//     setOpen(false); // Close the modal after submission
//   };

//   return (

//     <ThemeProvider theme={theme}>
//       <Modal
//         open={open}
//         onClose={() => setOpen(false)} // Close modal when clicking outside
//         aria-labelledby="add-complaint-reason"
//         aria-describedby="add-complaint-reason-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: isMobile ? "350px" : "500px",
//             backgroundColor: "#fff",
//             padding: 3,
//             borderRadius: 2,
//             boxShadow: 24,
//           }}
//         >
//           <Typography
//             id="add-complaint-reason"
//             variant="h6"
//             component="h1"
//             sx={{ marginBottom: 3, fontWeight: "bold" }}
//           >
//             Add Complaint Reason
//           </Typography>
//           <Box
//             component="form"
//             onSubmit={handleSubmit}
//             sx={{ width: "100%", display: "flex", flexDirection: "column" }}
//           >
//             <TextField
//               label="Enter Reason"
//               required
//               fullWidth
//               value={reason}
//               onChange={(e) => setReason(e.target.value)}
//               variant="outlined"
//               margin="normal"
//             />
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               color="warning"
//               sx={{ marginTop: 2 }}
//             >
//               Submit
//             </Button>
//           </Box>
//         </Box>
//       </Modal>

//       <Fab
//         color="primary-main"
//         sx={{
//           marginBottom: '55px',
//           marginRight: '20px',
//           position: 'fixed',
//           bottom: 16,
//           right: 16,
//           zIndex: 1000,
//         }}
//         onClick={handleFabClick}
//       >
//         <AddIcon />
//       </Fab>
//     </ThemeProvider >
//   );
// }



// ComplaintManagemnent.propTypes = {};

// ComplaintManagemnent.defaultProps = {};

// export default ComplaintManagemnent;


import React from 'react';
import PropTypes from 'prop-types';
import './ComplaintManagemnent.scss';
import { ThemeProvider, createTheme, } from '@mui/material/styles';
import { useAuth } from '../../services/authContext';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Fab,
  Select,
  MenuItem,
  Card,
  CardMedia,
  CardContent,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  SelectChangeEvent,
  Popover,
  Button,
  Grid,
  List, ListItem, ListItemText, ListItemAvatar, Avatar, Modal, Fade, Paper, useMediaQuery,
  listClasses, TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentIcon from '@mui/icons-material/Assignment'

const ComplaintManagemnent = () => {
  const { loginStatus, setLoginStatus } = useAuth();
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [listApiFlag, setListApiFlag] = React.useState(true);
  const [ListProductApi, setListProductApi] = React.useState([]);
  const [reason, setReason] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);


















  const theme = createTheme({
    palette: {
      primary: {
        main: '#284d84', // blue color
      },
      secondary: {
        main: '#EC4899', // Pink color for the check icons
      },
    },
    typography: {
      fontFamily: 'Arial, sans-serif',
    },
    
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));




  const handleFabClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };




  const handleSubmit = (e) => {
    e.preventDefault();
    setListOfUsers((prevList) => [...prevList, reason]); // Add the reason to the list
    setReason(""); // Clear the input
    setOpen(false); // Close the modal
  };





  React.useEffect(() => {
    const loginStat = localStorage.getItem("loginCred");
    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat));
    }
    if (listApiFlag && loginStatus.user && loginStatus.user.is_superuser) {
      const fetchListProducts = async () => {
        try {
          const response = await ListProductApi();
          setListOfUsers(response);
          setListApiFlag(false);
        } catch (error) {
          console.error("Error fetching users:", error);
          setListApiFlag(false);
        }
      };

      fetchListProducts();
    }

  }, [loginStatus]);



  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 1,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          marginTop: { xs: '80px', md: '130px' }, // Adjust top margin for smaller screens
        }}
      >
        {/* <AppBar position="fixed" color="primary" sx={{ marginTop: isMobile ? "56px" : "130px", }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <ArrowBack
                sx={{
                  backgroundcolor: 'black',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '50%'
                }}
                component={Link}
                to="/" />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Examination
            </Typography>
          </Toolbar>
        </AppBar> */}



        <Box sx={{ flexGrow: 1, height: '100vh', bgcolor: '#f5f5f5' }}>
          <AppBar position="fixed" color="primary" sx={{ marginTop: isMobile ? "56px" : "130px", }}>
            <Toolbar>
              {/* Status Bar */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '24px',
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  color: 'white',
                }}
              >


              </Box>

              {/* Main Toolbar */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="back"
                sx={{ mt: 3 }}
              >
                <Link to="/previous-page">
                  <ArrowBackIcon style={{ color: 'white' }} />
                </Link>
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1, mt: 3 }}>
                Add Complaint Reason
              </Typography>
            </Toolbar>
          </AppBar>
          {loginStatus.token && (
            <Box sx={{
              p: 2, mt: 2, position: 'fixed', marginLeft: isMobile ? "0px" : "510px", marginTop: isMobile ? "30px" : "70px", display: 'flex', justifyContent: 'center', alignItems: 'center',
              sm: 'center', lg: 'center', xl: 'center',
              padding: '-20px', // Adjust padding or any other styles for iPad
              // backgroundColor: 'lightblue', // Example background color

            }}>




              <Modal
                open={open}
                onClose={() => setOpen(false)} // Close modal when clicking outside
                aria-labelledby="add-complaint-reason"
                aria-describedby="add-complaint-reason-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: isMobile ? "350px" : "500px",
                    backgroundColor: "#fff",
                    padding: 3,
                    borderRadius: 2,
                    boxShadow: 24,
                  }}
                >
                  <Typography
                    id="add-complaint-reason"
                    variant="h6"
                    component="h1"
                    sx={{ marginBottom: 3, fontWeight: "bold" }}
                  >
                    Add Complaint Reason
                  </Typography>
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{ width: "100%", display: "flex", flexDirection: "column" }}
                  >
                    <TextField
                      label="Enter Reason"
                      required
                      fullWidth
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      variant="outlined"
                      margin="normal"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="warning"
                      sx={{ marginTop: 2 }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Modal>


            </Box>
          )}
        </Box>





        <Grid
          container
          spacing={2}
          position={"fixed"}

          sx={{
            marginBottom: "500px",
            position: "fixed",
            padding: "80px",
            width: isMobile ? "450px" : "1200px",
            marginLeft: isMobile ? "-80px" : "270px",
            maxHeight: "400px", // Set a maximum height for the scrollable area
            overflow: "auto" // Enables scrolling
            
          }}
        >
          {listOfUsers.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="body1">{item}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>


        <Fab
          color="primary"
          sx={{
            marginBottom: '55px',
            marginRight: '20px',
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000,

          }}
          onClick={handleFabClick}
        >
          {/* Add your icon here, for example: */}
          <AddIcon />
        </Fab>



        <Grid
          container
          spacing={2}
          position={"fixed"}

          sx={{
            marginBottom: "200px",
            position: "fixed",
            padding: "80px",
            width: isMobile ? "450px" : "1200px",
            marginLeft: isMobile ? "-80px" : "270px",
            maxHeight: "600px", // Set a maximum height for the scrollable area
            overflow: "auto" // Enables scrolling
            
          }}
        >
          {listOfUsers.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="body1">{item}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

      </Box>
    </ThemeProvider >
  );


}

ComplaintManagemnent.propTypes = {};

ComplaintManagemnent.defaultProps = {};

export default ComplaintManagemnent;

