import React from 'react';
import PropTypes from 'prop-types';
import './ExaminationManagement.scss';
import { useAuth } from '../../services/authContext';
import { ThemeProvider, createTheme, } from '@mui/material/styles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Fab,
  Select,
  MenuItem,
  Card,
  CardContent,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  SelectChangeEvent, useMediaQuery,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentIcon from '@mui/icons-material/Assignment'







const theme = createTheme({
  palette: {
    primary: {
      main: '#284d84' // blue
    },
  },
});





const ExaminationManagement = () => {
  const [day, setDay] = React.useState('thursday');
  const [value, setValue] = React.useState(2);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loginStatus, setLoginStatus } = useAuth();
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [listApiFlag, setListApiFlag] = React.useState(true);
  const [ListProductApi, setListProductApi] = React.useState([]);

  const menuItems = [
    { title: 'Examination', icon: <AssignmentIcon /> },
    { title: 'Create Result', icon: <AssignmentIcon /> },
    { title: 'Publish Result', icon: <AssignmentIcon /> },
    { title: 'Result', icon: <AssignmentIcon /> },
  ]


  React.useEffect(() => {
    const loginStat = localStorage.getItem("loginCred");
    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat));
    }
    if (listApiFlag && loginStatus.user && loginStatus.user.is_superuser) {
      const fetchListProducts = async () => {
        try {
          const response = await ListProductApi();
          setListOfUsers(response);
          setListApiFlag(false);
        } catch (error) {
          console.error("Error fetching users:", error);
          setListApiFlag(false);
        }
      };

      fetchListProducts();
    }

  }, [loginStatus]);

  
  const handleChange = (event) => {
    setDay(event.target.value);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 1,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          marginTop: { xs: '80px', md: '130px' }, // Adjust top margin for smaller screens
        }}
      >
        {/* <AppBar position="fixed" color="primary" sx={{ marginTop: isMobile ? "56px" : "130px", }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <ArrowBack
                sx={{
                  backgroundcolor: 'black',
                  color: 'white',
                  padding: '8px',
                  borderRadius: '50%'
                }}
                component={Link}
                to="/" />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Examination
            </Typography>
          </Toolbar>
        </AppBar> */}



        <Box sx={{ flexGrow: 1, height: '100vh', bgcolor: '#f5f5f5' }}>
          <AppBar position="fixed" color="primary" sx={{ marginTop: isMobile ? "56px" : "130px", }}>
            <Toolbar>
              {/* Status Bar */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '24px',
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  color: 'white',
                }}
              >


              </Box>

              {/* Main Toolbar */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="back"
                sx={{ mt: 3 }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1, mt: 3 }}>
                Examination
              </Typography>
            </Toolbar>
          </AppBar>
          {loginStatus.token && (
          <Box sx={{
            p: 2, mt: 2, position: 'fixed', marginLeft: isMobile ? "0px" : "510px", marginTop: isMobile ? "30px" : "70px", display: 'flex', justifyContent: 'center', alignItems: 'center',
            sm: 'center', lg: 'center', xl: 'center',
            padding: '-20px', // Adjust padding or any other styles for iPad
            // backgroundColor: 'lightblue', // Example background color
          
          }}>
          <Grid container spacing={2}>
            {menuItems.map((item, index) => (
              <Grid item xs={6} key={index}>
                <Card
                  sx={{
                    textAlign: 'center',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ":hover": { backgroundColor: '#e6e6e6', }
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        bgcolor: '#f5f5f5',
                        borderRadius: '50%',
                        width: 80,
                        height: 80,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 2,
                      }}
                    >
                      <Box sx={{ color: '#1976d2', transform: 'scale(1.5)' }}>
                        {item.icon}
                      </Box>
                    </Box>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ color: '#1976d2' }}
                    >
                      {item.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
          )}
      </Box>




      <Container
        sx={{
          flexGrow: 1,
          mt: { xs: 2, md: 5 }, // Margin for small and medium screens
          mb: { xs: 5, md: 7 }, // Adjust bottom margin
          bgcolor: 'background.paper',
        }}
      >

      </Container>
      <Fab
        color="primary"
        sx={{
          marginBottom: '55px',
          marginRight: '20px',
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1000,
        }}
      >
        {/* Add your icon here, for example: */}
        <AddIcon />
      </Fab>
    </Box>
    </ThemeProvider >
  );
}
// const ExaminationManagement = () => (
//   <div className="ExaminationManagement" data-testid="ExaminationManagement">
//     ExaminationManagement Component
//   </div>
// );

ExaminationManagement.propTypes = {};

ExaminationManagement.defaultProps = {};

export default ExaminationManagement;
