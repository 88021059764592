import React from 'react';
import PropTypes from 'prop-types';
import './VideoManagemnent.scss';

const VideoManagemnent = () => (
  <div className="VideoManagemnent" data-testid="VideoManagemnent">
    VideoManagemnent Component
  </div>
);

VideoManagemnent.propTypes = {};

VideoManagemnent.defaultProps = {};

export default VideoManagemnent;
