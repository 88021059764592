import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth } from '../../services/authContext';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Container,
  Fab,
  Card,
  CardMedia,
  Button,
  Modal,
  Fade,
  Grid,
  Popover,
  useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const theme = createTheme({
  palette: {
    primary: { main: '#284d84' }, // Blue color
    secondary: { main: '#EC4899' }, // Pink color
  },
  typography: { fontFamily: 'Arial, sans-serif' },
});

const GalleryManagement = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [showText, setShowText] = useState(true); // New state to control visibility
  const fileInputRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loginStatus, setLoginStatus } = useAuth();

  useEffect(() => {
    const loginStat = localStorage.getItem('loginCred');
    if (loginStat) {
      setLoginStatus(JSON.parse(loginStat));
    }
  }, [setLoginStatus]);

  const handleFabClick = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleCameraClick = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        const videoElement = document.createElement('video');
        videoElement.srcObject = stream;
        videoElement.play();
        document.body.appendChild(videoElement);
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });
    setPopoverAnchor(null);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
    setPopoverAnchor(null);
  };

  const handleImageSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((prevImages) => [...prevImages, reader.result]);
        setShowText(false); // Hide text when an image is selected
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleDeleteImage = (imageToDelete) => {
    setImages((prevImages) => prevImages.filter((image) => image !== imageToDelete));
  };

  const handleModalOpen = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setSelectedImage(null);
    setOpenModal(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, height: '100vh', display: 'flex', flexDirection: 'column', mt: { xs: '80px', md: '130px' } }}>
        <AppBar position="fixed" color="primary" sx={{ mt: isMobile ? '56px' : '130px' }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
              component={Link}
              to="/"
            >
              <ArrowBack />
            </IconButton>
            <Typography
              variant={isMobile ? 'subtitle1' : 'h6'}
              component="div"
              sx={{ flexGrow: 1, textAlign: isMobile ? 'center' : 'left' }}
            >
              Gallery
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Conditionally render the typography based on showText */}
        {showText && (
          <>
            <Typography variant="h4" component="h1" gutterBottom fontWeight="bold" sx={{ mt: 2, marginLeft: isMobile ? "42px" : "570px", marginTop: isMobile ? "42px" : "170px" }}>
              Click a Photo
              or <Box component="span" color="primary.main">browse to upload.</Box>
            </Typography>

            <Typography
              variant="contained"
              size="large"
              sx={{
                mt: 3,
                px: 4,
                py: 1.5,
                borderRadius: '50px',
                fontSize: '1.1rem', marginLeft: isMobile ? "76px" : "170px",
                fontSize: '1.5rem', // Increased font size
                fontWeight: 'bold', // Make the font bold
                marginLeft: isMobile ? "" : "600px"
              }}
            >
              Upload your photo
            </Typography><span style={{ fontSize: "40px", marginLeft: "850px", marginTop: isMobile ? "" : "-60px" }} >&#128512;</span>

            <Typography variant="body2" color="text.secondary" sx={{ mt: 2, marginLeft: isMobile ? "56px" : "600px", }}>
              File must be JPEG, JPG or PNG and up to 40MB
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mt: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                <CheckCircleIcon sx={{ marginLeft: isMobile ? "10px" : "0px" }} color="secondary" fontSize="small" />
                <Typography sx={{ marginLeft: isMobile ? "10px" : "0px" }} variant="body2">High Picture Quality</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CheckCircleIcon color="secondary" fontSize="small" />
                <Typography variant="body2">Like and Save whenever you want</Typography>
              </Box>
            </Box>

            <Typography variant="body2" align="center" sx={{ mt: 2, marginLeft: isMobile ? "11px" : "170px", }}>
              By uploading your image or video, read the {' '}
              <Link href="#" color="primary">
                Terms of use
              </Link>
              {' '}and{' '}
              <Link href="#" color="primary">
                Privacy Policy
              </Link>
            </Typography>
          </>
        )}

        <Container sx={{ mt: { xs: 2, md: 5 }, mb: { xs: 5, md: 7 } }}>
          <Grid container spacing={2} justifyContent="center">
            {images.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => handleModalOpen(image)}
                >
                  <CardMedia component="img" image={image} alt={`Uploaded Image ${index + 1}`} sx={{ height: 200, objectFit: 'cover' }} />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteImage(image);
                    }}
                    sx={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Modal open={openModal} onClose={handleModalClose} closeAfterTransition>
            <Fade in={openModal}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                }}
              >
                {selectedImage && (
                  <CardMedia
                    component="img"
                    image={selectedImage}
                    alt="Selected Image"
                    sx={{ width: '100%', height: 'auto' }}
                  />
                )}
              </Box>
            </Fade>
          </Modal>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleImageSelect}
          />

          <Fab
            color="primary"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            onClick={handleFabClick}
          >
            <AddIcon />
          </Fab>

          <Popover
            open={Boolean(popoverAnchor)}
            anchorEl={popoverAnchor}
            onClose={() => setPopoverAnchor(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CameraAltIcon />}
                onClick={handleCameraClick}
                sx={{ mb: 1 }}
              >
                Open Camera
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CloudUploadIcon />}
                onClick={handleUploadClick}
              >
                Upload Picture
              </Button>
            </Box>
          </Popover>
        </Container>
      </Box >
    </ThemeProvider >
  );
};

GalleryManagement.propTypes = {};
GalleryManagement.defaultProps = {};

export default GalleryManagement;
